import React, {
    useLayoutEffect,
    useMemo,
    useState,
    useCallback,
} from 'react';
import { buildClassNames } from '../utils/classNameUtils';

import styles from './overlay.module.scss';

export interface IProps {
    isShown?: boolean;
    zIndex?: number;
    onClick?: () => void;
}

const ANIMATION_DURATION_MS = 250;

const Overlay: React.FC<IProps> = props => {
    const {
        isShown = true,
        zIndex,
    } = props;
    const [ transition, setTransition ] = useState(false);

    const computedInlineStyles = useMemo<React.CSSProperties>(() => ({
        zIndex,
    }), [ zIndex ]);

    useLayoutEffect(() => {
        if (isShown) {
            setTimeout(() => setTransition(true), 100);
        } else {
            setTransition(true);

            const timerId = setTimeout(() => {
                setTransition(false);
            }, ANIMATION_DURATION_MS);

            return () => {
                clearTimeout(timerId);
            };
        }
    }, [ isShown ]);

    const didClicked = useCallback(() => props.onClick && props.onClick(), []);

    const containerClassName = buildClassNames(styles, [
        'overlay',
        isShown && transition && 'overlay--after-open',
        !isShown && 'overlay--before-close',
    ]);

    return ((isShown || transition) &&
        <div className={containerClassName} style={computedInlineStyles} onClick={didClicked} />
    );
};

export default Overlay;