import React, {
    useCallback,
    useMemo,
} from 'react';
import Expand from 'react-expand-animated';
import {
    formatAmountText,
    formatStringWithNonBreakingSpace,
} from '@frontend/jetlend-core/src/formatters/formatUtils';
import convertFullNameToShortName from '@frontend/jetlend-core/src/formatters/convertFullNameToShortName';
import iconChevronRight from '@frontend/jetlend-assets/icons/icon-chevron--right.svg';
import isCompanyName from '@frontend/jetlend-core/src/formatters/isCompanyName';
import styles from './UserAccountsGroup.module.scss';
import UserAccountChoiceButton, { IProps as UserAccountChoiceButtonProps } from '../UserAccountChoiceButton/UserAccountChoiceButton';
import { buildClassNames } from '@ui/utils/classNameUtils';
import Tooltip from '@ui/ui/TooltipIcon/Tooltip';
import { getAssetSrc } from '@ui/utils/getAssetSrc';

export type DefaultAccountType = Pick<UserAccountChoiceButtonProps, 'id' | 'name' | 'amount'>;
export type PassedAccountComponentProps = Pick<UserAccountChoiceButtonProps, 'active' | 'onClick'>;

export interface IProps<TAccount extends { id: number } = DefaultAccountType> {
    /**
     * Идентификатор основного счета пользователя.
     */
    id: number;
    /**
     * Идентификатор текущего активного счета пользователя.
     */
    activeAccountId?: number;
    /**
     * Имя пользователя группы счетов.
     */
    name: string;
    /**
     * Общая сумма счетов пользователя.
     */
    amount?: number;
    /**
     * Тип отображения баланса.
     */
    amountType?: 'default'|'success'|'danger';
    /**
     * Краткая подсказка, отображаемая в списке счетов.
     */
    accountsHint?: string;
    /**
     * Список доступных счетов пользователя.
     */
    accounts?: TAccount[];
    /**
     * Компонент, который будет отображен в подвале списка счетов.
     */
    accountsFooter?: React.ReactNode;
    /**
     * Компонент для отображения кнопки выбора счета.
     */
    UserAccountComponent?: React.ComponentType<TAccount & PassedAccountComponentProps>;
    /**
     * @event
     * Событие, которое срабатывает, когда какой либо счет был выбран.
     * @param id Уникальный идентификатор выбранного счета.
     */
    onAccountClick?: (id: number) => void;
}

/**
 * Компонент для отображения группы счетов одного пользователя с возможностью их переключения.
 * В зависимости от количества доступных счетов в группе при нажатии на компонент он будет либо раскрываться,
 * если у пользователя есть хотя бы один счет, либо выбирается счет с текущим идентификатором.
 *
 * Если у пользователя несколько счетов и один из них в данный момент активен, то список счетов будет раскрыт.
 * По-умолчанию список счетов не раскрывается.
 *
 * Компонент предназначен для использования в компоненте {@link UserSidePanel}
 */
export default function UserAccountsGroup<TAccount extends { id: number }>({
    id,
    activeAccountId,
    name,
    amount,
    amountType = 'default',
    accountsHint = 'Выберите портфель',
    accountsFooter,
    accounts,
    onAccountClick,
    UserAccountComponent = UserAccountChoiceButton,
}: IProps<TAccount>) {
    const hasAnyActiveAccount = useMemo<boolean>(() => {
        // Если нет счетов, то активный счет определяется по основному идентификатору пользователя
        if (!accounts) {
            return id === activeAccountId;
        }

        return accounts?.some(account => account.id === activeAccountId) ?? false;
    }, [ id, accounts, activeAccountId ]);

    const hasAnyAccount = accounts && accounts.length > 0;
    const hasAmount = typeof amount === 'number' && Number.isFinite(amount);

    const [ expanded, setExpanded ] = React.useState<boolean>(hasAnyActiveAccount);

    const didHeaderClicked = useCallback(() => {
        if (hasAnyAccount) {
            setExpanded(value => !value);
            return;
        }

        if (onAccountClick && id > 0) {
            onAccountClick(id);
        }
    }, [ id, onAccountClick, hasAnyAccount ]);

    const didAccountClicked = useCallback((clickedAccountId: number) => {
        if (onAccountClick) {
            onAccountClick(clickedAccountId);
        }
    }, [ onAccountClick ]);

    // Названия компаний необходимо показывать полностью, чтобы отличать сокращение ФЛ от ИП
    const shortName = isCompanyName(name)
        ? name
        : convertFullNameToShortName(name);

    const iconClassName = buildClassNames(styles, [
        'icon',
        hasAnyActiveAccount && 'icon--active',
    ]);

    const controlIconClassName = buildClassNames(styles, [
        'control-icon',
        hasAnyAccount && expanded && 'control-icon--expanded',
        hasAnyAccount && !expanded && 'control-icon--collapsed',
    ]);

    const amountClassName = buildClassNames(styles, [
        'amount',
        `amount--type-${amountType}`,
    ]);

    return (
        <div className={styles['container']}>
            <Tooltip title={name}>
                <button type="button" className={styles['header']} onClick={didHeaderClicked}>
                    <div className={iconClassName}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.09 6.98002C20.24 6.04002 18.82 5.57002 16.76 5.57002H16.52V5.53002C16.52 3.85002 16.52 1.77002 12.76 1.77002H11.24C7.48004 1.77002 7.48004 3.86002 7.48004 5.53002V5.58002H7.24004C5.17004 5.58002 3.76004 6.05002 2.91004 6.99002C1.92004 8.09002 1.95004 9.57002 2.05004 10.58L2.06004 10.65L2.16004 11.7C2.17004 11.71 2.19004 11.73 2.21004 11.74C2.54004 11.96 2.88004 12.18 3.24004 12.38C3.38004 12.47 3.53004 12.55 3.68004 12.63C5.39004 13.57 7.27004 14.2 9.18004 14.51C9.27004 15.45 9.68004 16.55 11.87 16.55C14.06 16.55 14.49 15.46 14.56 14.49C16.6 14.16 18.57 13.45 20.35 12.41C20.41 12.38 20.45 12.35 20.5 12.32C20.96 12.06 21.39 11.78 21.81 11.47C21.83 11.46 21.85 11.44 21.86 11.42L21.9 11.06L21.95 10.59C21.96 10.53 21.96 10.48 21.97 10.41C22.05 9.40002 22.03 8.02002 21.09 6.98002ZM13.09 13.83C13.09 14.89 13.09 15.05 11.86 15.05C10.63 15.05 10.63 14.86 10.63 13.84V12.58H13.09V13.83ZM8.91004 5.57002V5.53002C8.91004 3.83002 8.91004 3.20002 11.24 3.20002H12.76C15.09 3.20002 15.09 3.84002 15.09 5.53002V5.58002H8.91004V5.57002Z" />
                            <path opacity="0.4" d="M20.5002 12.3C20.4502 12.33 20.4002 12.36 20.3502 12.39C18.5702 13.43 16.6002 14.13 14.5602 14.47C14.4802 15.43 14.0602 16.53 11.8702 16.53C9.68016 16.53 9.26016 15.44 9.18016 14.49C7.27016 14.19 5.39016 13.56 3.68016 12.61C3.53016 12.53 3.38016 12.45 3.24016 12.36C2.88016 12.16 2.54016 11.94 2.21016 11.72C2.19016 11.71 2.17016 11.69 2.16016 11.68L2.77016 18.19C2.98016 20.18 3.80016 22.23 8.20016 22.23H15.8202C20.2202 22.23 21.0402 20.18 21.2502 18.18L21.8802 11.4C21.8702 11.42 21.8502 11.44 21.8302 11.45C21.4002 11.76 20.9602 12.05 20.5002 12.3Z" />
                        </svg>
                    </div>
                    <div className={styles['name']}>
                        {shortName}
                    </div>
                    {hasAmount && (
                        <div className={amountClassName}>
                            {formatStringWithNonBreakingSpace(formatAmountText(amount))}
                        </div>
                    )}
                    <div className={styles['control-button']}>
                        <img
                            src={getAssetSrc(iconChevronRight)}
                            alt=""
                            className={controlIconClassName}
                        />
                    </div>
                </button>
            </Tooltip>
            {hasAnyAccount && (
                <Expand duration={250} open={expanded}>
                    <div className={styles['accounts']}>
                        <div className={styles['accounts__hint']}>
                            {accountsHint}
                        </div>
                        {accounts?.map(account => (
                            <UserAccountComponent
                                key={account.id}
                                {...account}
                                active={account.id === activeAccountId}
                                onClick={didAccountClicked}
                            />
                        ))}
                        {accountsFooter && (
                            <div className={styles['accounts__footer']}>
                                {accountsFooter}
                            </div>
                        )}
                    </div>
                </Expand>
            )}
        </div>
    );
}