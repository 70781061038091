import React from 'react';
import { IInvestorPortfolioApiModel } from '@frontend/jetlend-core/src/models/account';
import UserAccountChoiceButton from '@ui/components/UserSidePanel/UserAccountChoiceButton/UserAccountChoiceButton';
import { PassedAccountComponentProps } from '@ui/components/UserSidePanel/UserAccountsGroup/UserAccountsGroup';
import { isInvestorPortfolioActive } from './CabinetUserSidePanel.logic';

export type IProps = IInvestorPortfolioApiModel & PassedAccountComponentProps;

/**
 * Компонент для кастомизации отображения портфеля инвестора в списке счетов.
 */
export default function InvestorUserAccount({
    amount,
    ...rest
}: IProps) {
    return (
        <UserAccountChoiceButton
            amount={amount}
            amountType={isInvestorPortfolioActive(amount) ? 'default' : 'danger'}
            {...rest}
        />
    );
}