import {
    COMPANY_NAME_PREFIXES,
    INDIVIDUAL_NAME_PREFIX,
} from './isCompanyName';

/**
 * Форматирует полное имя пользователя (ФИО) в сокращенный формат (Фамилия И. О.).
 *
 * Поддерживает форматирование юридических форм имени (ИП и ООО).
 *
 * @param fullName Полное имя (ФИО)
 * @returns Сокращенное имя пользователя (Фамилия И. О.)
 *
 * @category Formatters
 *
 * @example
 * convertFullNameToShortName('Мулюкина Екатерина Андреевна') // 'Мулюкина Е. А.'
 * convertFullNameToShortName('ИП Мулюкина Екатерина Андреевна') // 'Мулюкина Е. А.'
 * convertFullNameToShortName('ООО Ромашки и Цветочки') // 'Ромашки и Цветочки'
 */
export default function convertFullNameToShortName(fullName: string) {
    if (!fullName) {
        return undefined;
    }

    const nameParts = fullName.split(' ');
    if (nameParts.length > 0) {
        const mayOrganizationPrefix = nameParts[0];

        if (COMPANY_NAME_PREFIXES.includes(mayOrganizationPrefix.toUpperCase())) {
            // Если это юридическая форма имени, удаляем префикс из имени
            nameParts.splice(0, 1);

            // Для ИП обрабатываем как обычное полное имя, для других (например, ООО) возвращаем полное имя организации
            // без префикс юридической формы организации.
            if (mayOrganizationPrefix !== INDIVIDUAL_NAME_PREFIX) {
                return nameParts.join(' ');
            }
        }

        // Если какая либо часть содержит цифры или скобки, то не форматируем
        if (nameParts.some(part => /[\d()]/.test(part))) {
            return fullName;
        }

        // Если имя состоит только из 2 частей, то форматируем их
        if (nameParts.length === 2) {
            return `${nameParts[0]} ${nameParts[1].charAt(0)}.`;
        }

        // Если имя состоит из 3 или более частей, форматируем первые три
        if (nameParts.length >= 3) {
            return `${nameParts[0]} ${nameParts[1].charAt(0)}. ${nameParts[2].charAt(0)}.`;
        }
    }

    return fullName;
}
