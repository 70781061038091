import React, { useCallback } from 'react';
import { IConnectableInputProps } from '@frontend/jetlend-core/src/ui/inputs/common';
import { connectToField } from '@frontend/jetlend-core/src/ui/inputs/connect';
import {
    buildClassNames,
    mergeClassNames,
} from '../../../utils/classNameUtils';
import { CheckboxComponent } from '../Checkbox/Checkbox';

import styles from './radioButton.module.scss';

export type MarkerColorType = 'primary' | 'error';

export interface IProps extends IConnectableInputProps {
    style?: 'check'|'radio';
    className?: string;
    label?: string|JSX.Element|React.ReactNode;
    labelClassName?: string;
    selectedValue?: any;
    disabled?: boolean;
    boldValues?: boolean;
    noMargin?: boolean;
    markerColor?: MarkerColorType;
    /**
     * Размер кнопки radio
     */
    size?: 'default' | 'small';
    onClick?: (e: React.MouseEvent) => void;
    onChange?: (value: boolean) => void;
}

export const RadioButton: React.FC<IProps> = props => {
    const {
        className,
        label,
        disabled,
        onClick,
        onChange,
        markerColor = 'primary',
        size = 'default',
    } = props;

    const name = props.name;
    const value = props.selectedValue;
    const currentValue = props.value;
    const checked = typeof currentValue !== 'undefined' && value === currentValue;

    const containerClassName = mergeClassNames([
        buildClassNames(styles, [
            'container',
            disabled && 'container--disabled',
            props.noMargin && 'container--no-margin',
        ]),
        className,
    ]);

    const radioClassName = buildClassNames(styles, [
        'radio',
        `radio--${markerColor}`,
        `radio--${size}`,
        checked && 'radio--checked',
    ]);

    const labelClassName = buildClassNames(styles, [
        'label',
        checked && 'label--checked',
        props.boldValues && 'label--bold-values',
    ], [ props.labelClassName ]);

    const didChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(value);
    }, [ value, onChange ]);

    const didClicked = useCallback((e: React.MouseEvent) => {
        // Перехватываем два события: клик на label и на input (особенности работы браузера с кликами на label)
        // https://stackoverflow.com/a/24501565
        // Прокидываем выше только одно событие, которое принадлежит input
        if ((e.target as HTMLElement)?.tagName?.toLowerCase() !== 'input') {
            return;
        }

        onClick && onClick(e);
    }, [ onClick ]);

    if (props.style === 'check') {
        return (
            <CheckboxComponent
                content={value}
                value={currentValue}
                onChange={didChanged}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                disabled={props.disabled}
                noMargin={props.noMargin}
                className={className}
                label={label}
            />
        );
    }

    return (
        // TODO Действительно тут нужен onClick именно на label?
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <label
            className={containerClassName}
            onClick={didClicked}
        >
            <input
                className={styles['input']}
                name={name}
                checked={checked}
                type="radio"
                onChange={didChanged}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
            />

            <span className={radioClassName} />

            <span className={labelClassName}>
                {label}
            </span>
        </label>
    );
};

export const RadioButtonField = connectToField(RadioButton);

export default RadioButton;