import { useEffect } from 'react';
import styles from './useHideJivoChat.module.scss';

/**
 * Скрывает кнопку Jivo чата на десктопе, чтобы она не мешала отображению всплывающего содержимого.
 * @param hidden флаг скрытия.
 */
export default function useHideJivoChat(hidden: boolean): void {
    useEffect(() => {
        if (!hidden) {
            return;
        }

        // Используем назначение глобального класса, чтобы скрыть кнопку Jivo чата на десктопе
        // т.к. он может загрузиться позднее и поиск по элементу не сработает корректно
        const className = styles['html-body'];
        document.body.classList.add(className);

        return () => {
            document.body.classList.remove(className);
        };
    }, [ hidden ]);

}