import React, { forwardRef } from 'react';
import {
    Scrollbar as ScrollbarLib,
    ScrollbarProps,
} from 'react-scrollbars-custom';
import {
    buildClassNames,
    mergeClassNames,
} from '../../utils/classNameUtils';

import styles from './scrollBar.module.scss';

export type ScrollBarInstance = ScrollbarLib;

type ScrollPositionType = 'left' | 'right';

export type IProps = Omit<ScrollbarProps, 'ref'> & {
    scrollPosition?: ScrollPositionType;
    visible?: boolean;
    disable?: boolean;
    wide?: boolean;
    hiddenScroll?: boolean;
    scrollContainerClassName?: string;
};

const ScrollBar = forwardRef<ScrollbarLib, IProps>(({
    children,
    disable,
    scrollPosition = 'left',
    scrollContainerClassName,
    ...props
}, ref) => {
    const wrapperStyles = buildClassNames(styles, [
        'wrapper',
        props.wide && 'wrapper--wide',
    ]);

    const yScrollTrackStyles = buildClassNames(styles, [
        'scroll-track',
        'scroll-track--vertical',
        scrollPosition === 'right' && 'scroll-track--vertical--right',
        !props.visible && 'scroll-track--hidden',
        props.hiddenScroll && 'scroll-track--max-hidden',
    ]);

    const xScrollTrackStyles = buildClassNames(styles, [
        'scroll-track',
        'scroll-track--horizontal',
        !props.visible && 'scroll-track--hidden',
        props.hiddenScroll && 'scroll-track--max-hidden',
    ]);

    if (disable) {
        return children;
    }

    const scrollBarClassName = mergeClassNames([
        styles['scroll'],
        scrollContainerClassName,
    ]);

    return (
        <ScrollbarLib
            ref={ref as any}
            className={scrollBarClassName}
            mobileNative
            wrapperProps={{
                renderer: props => {
                    const {
                        elementRef,
                        ...restProps
                    } = props;
                    return <div {...restProps} ref={elementRef} className={wrapperStyles} />;
                },
            }}
            trackYProps={{
                renderer: props => {
                    const {
                        elementRef,
                        ...restProps
                    } = props;
                    return <div {...restProps} ref={elementRef} className={yScrollTrackStyles} />;
                },
            }}
            thumbYProps={{
                renderer: props => {
                    const {
                        elementRef,
                        ...restProps
                    } = props;
                    return <div {...restProps} ref={elementRef} className={styles['scroll-thumb']} />;
                },
            }}
            trackXProps={{
                renderer: props => {
                    const {
                        elementRef,
                        ...restProps
                    } = props;
                    return <div {...restProps} ref={elementRef} className={xScrollTrackStyles} />;
                },
            }}
            thumbXProps={{
                renderer: props => {
                    const {
                        elementRef,
                        ...restProps
                    } = props;
                    return <div {...restProps} ref={elementRef} className={styles['scroll-thumb']} />;
                },
            }}
            {...props}
        >
            {children}
        </ScrollbarLib>
    );
});


export default React.memo(ScrollBar);