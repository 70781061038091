import React, { CSSProperties } from 'react';
import iconUser from '@frontend/jetlend-assets/icons/icon--user.svg';
import { IClassName } from '@frontend/jetlend-core/src/models/common';
import { formatNameToInitials } from '@frontend/jetlend-core/src/formatters/formatUtils';
import styles from './UserIcon.module.scss';
import { getAssetSrc } from '@ui/utils/getAssetSrc';
import { buildClassNames } from '@ui/utils/classNameUtils';

export type UserIconSize = 'default'|'large';

export interface IProps extends IClassName {
    /**
     * Размер иконки пользователя.
     */
    size?: UserIconSize;
    /**
     * Отображает инициалы вместо иконки.
     */
    name?: string;
    /**
     * Иконка
     */
    image?: string;
    /**
     * Бадж над иконкой пользователя
     */
    badge?: React.ReactNode;
}

/**
 * Компонент отображающий иконку пользователя.
 */
export default function UserIcon({
    className,
    size = 'default',
    name,
    image,
    badge,
}: IProps) {
    const containerClassName = buildClassNames(styles, [
        'container',
        `container--size-${size}`,
    ], [ className ]);

    const initials = typeof name === 'string'
        ? formatNameToInitials(name)
        : null;

    const hasInitials = typeof initials === 'string' && initials !== '—';

    const iconContainerInlineStyle: CSSProperties = {
        backgroundColor: hasInitials ? '#5FA9FF' : undefined,
    };

    return (
        <div className={containerClassName}>
            <div className={styles['icon']} style={iconContainerInlineStyle}>
                {hasInitials && !image
                    ? (
                        <div className={styles['icon__initials']}>
                            {initials}
                        </div>
                    )
                    : (
                        <img
                            className={image ? styles['icon__image'] : styles['icon__default']}
                            src={getAssetSrc(image ?? iconUser)}
                            alt=""
                        />
                    )}
            </div>
            {badge && (
                <div className={styles['badge']}>
                    {badge}
                </div>
            )}
        </div>
    );
}