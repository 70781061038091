import React, { useCallback } from 'react';

import { isEmptyValue } from '@frontend/jetlend-core/src/validators';
import { IConnectableInputProps } from '@frontend/jetlend-core/src/ui/inputs/common';
import { connectToField } from '@frontend/jetlend-core/src/ui/inputs/connect';
import styles from './checkbox.module.scss';
import {
    buildClassNames,
    mergeClassNames,
} from '@ui/utils/classNameUtils';
import { wrapWithStopPropagation } from '@ui/utils/stopPropagation';

export type CheckboxType = 'square' | 'tick';

export type IProps = IConnectableInputProps & React.PropsWithChildren & {
    className?: string;
    label?: string|JSX.Element|React.ReactNode;
    content?: any;
    disabled?: boolean;
    noMargin?: boolean;
    type?: CheckboxType;
    vAlign?: 'middle'|'top';
    onClick?: (e: React.MouseEvent) => void;
    /**
     * Флаг, показывающий, что чекбокс по умолчанию красный
     */
    defaultInvalid?: boolean;
}

export const CheckboxComponent: React.FC<IProps> = props => {
    const {
        className,
        label,
        value,
        disabled,
        children,
        onChange,
        type = 'tick',
        vAlign = 'middle',
    } = props;

    const isDefaultType = type === 'tick';

    const containerClassName = mergeClassNames([
        buildClassNames(styles, [
            'container',
            `container--valign-${vAlign}`,
            props.noMargin && 'container--no-margin',
            disabled && 'container--disabled',
        ]),
        className,
    ]);

    const isEmptyContent = isEmptyValue(props.content);
    const isChecked = isEmptyContent
        ? props.value === true
        : props.value === props.content;

    const hasError = (props.defaultInvalid && !isChecked) || (props.invalid && (props.dirty || props.submitFailed));

    const didChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) {
            return;
        }

        // e.stopPropagation();

        const willChecked = e.currentTarget.checked;

        let value;
        if (isEmptyContent) {
            value = willChecked;
        } else {
            value = willChecked ? props.content : undefined;
        }

        onChange && onChange(value);
    }, [ onChange, isEmptyContent, props.content, disabled, isChecked ]);

    const checkboxClassName = buildClassNames(styles, [
        'checkbox',
        isChecked && 'checkbox--checked',
        isDefaultType ? 'checkbox--tick' : 'checkbox--square',
        hasError && 'checkbox--error',
    ]);

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <label
            className={containerClassName}
            onClick={wrapWithStopPropagation(props.onClick)}
        >
            <input
                className={styles['input']}
                name={props.name}
                checked={value}
                type="checkbox"
                disabled={disabled}
                onChange={didChanged}
            />

            <div className={checkboxClassName} />

            <div className={styles['label']}>
                {label}{children}
            </div>
        </label>
    );
};

export default connectToField(CheckboxComponent);