import {
    ClientType,
    IInvestorAccountUserApiModel,
} from '@frontend/jetlend-core/src/models/account';

export const DEFAULT_USERS_ORDER: ClientType[] = [
    ClientType.Investor,
    ClientType.Borrower,
    ClientType.Partner,
];

export function reorderUserGroups(currentClientType?: ClientType): ClientType[] {
    if (!DEFAULT_USERS_ORDER.includes(currentClientType)) {
        return DEFAULT_USERS_ORDER;
    }

    const result = [...DEFAULT_USERS_ORDER];
    result.splice(result.indexOf(currentClientType), 1);
    result.unshift(currentClientType);

    return result;
}

export function isInvestorPortfolioActive(amount: number): boolean {
    return amount === 0 || amount > 10000;
}

export function isInvestorHasAnyActivePortfolio(investor: IInvestorAccountUserApiModel): boolean {
    if (!investor) {
        return false;
    }

    return investor.portfolios?.some(portfolio => isInvestorPortfolioActive(portfolio.amount)) ?? false;
}
