import { useSelector } from 'react-redux';
import { useActions } from './useActions';
import { IModalHandlerV2 } from '@core/ducks/v2/modal';

/**
 * Хук для управления одиночным модальным окном {@link modalHandlerV2} внутри компонента модального окна.
 *
 * @param handler Хендлер модального окна.
 * @returns Возвращает флаг открытия модального окна, функцию закрытия и состояние модального окна.
 * @category Hooks
 *
 * @see {@link useIsModalOpen}
 * @see {@link modalHandlerV2}
 *
 * @example
 * const [ isOpen, close, state ] = useModalHandlerV2(sprintCreateModalHandler);
 */
export default function useModalHandlerV2<TModalState = undefined>(handler: IModalHandlerV2<TModalState>): [
    boolean,
    () => void,
    TModalState
] {
    const close = useActions(handler.close);
    const isOpen = useSelector(handler.isOpen);
    const state = useSelector(handler.selector);

    return [
        isOpen,
        close,
        state,
    ];
}
