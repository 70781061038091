import React, { useCallback } from 'react';
import iconLogout from '@frontend/jetlend-assets/icons/icon--logout.svg';
import { ScrollState } from 'react-scrollbars-custom/dist/types/types';
import Overlay from '@ui/layout/Overlay';
import styles from './UserSidePanel.module.scss';
import {
    buildClassNames,
    mergeClassNames,
} from '@ui/utils/classNameUtils';
import useScrollDisable from '@ui/hooks/useScrollDisable';
import IconButton from '@ui/ui/inputs/IconButton/IconButton';
import ScrollBar from '@ui/components/ScrollBar/ScrollBar';
import ClientPortal from '@ui/ui/ClientPortal/ClientPortal';

export interface IProps extends React.PropsWithChildren {
    /**
     * Открыта ли боковая панель.
     */
    isOpen?: boolean;
    /**
     * Содержимое заголовка.
     */
    header?: React.ReactNode;
    /**
     * Содержимое футера.
     */
    footer?: React.ReactNode;
    /**
     * @event
     * Событие, которое срабатывает при нажатии на кнопку закрытия боковой панели.
     */
    onClose?: () => void;
    /**
     * @event
     * Событие, которое срабатывает при нажатии на кнопку выхода из аккаунта.
     */
    onLogout?: () => void;
}

/**
 * Боковая панель для отображения панели информации о текущем пользователе и списке доступных аккаунтов.
 */
export default function UserSidePanel({
    isOpen = false,
    header,
    children,
    footer,
    onClose,
    onLogout,
}: IProps) {
    // При открытии боковой панели необходимо отключить скролл всей страницы
    useScrollDisable(isOpen);

    const didClosed = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [ onClose ]);

    const [ hasTopScrollShadow, setTopScrollShadow ] = React.useState(false);
    const [ hasBottomScrollShadow, setBottomScrollShadow ] = React.useState(false);

    // Функция для расчета тени вокруг контейнера при наличии скролла
    const didRecomputeScrollShadows = useCallback((scrollValues: ScrollState) => {
        const hasScroll = scrollValues.clientHeight < scrollValues.scrollHeight;

        setTopScrollShadow(hasScroll && scrollValues.scrollTop > 0);
        setBottomScrollShadow(hasScroll && scrollValues.scrollTop < scrollValues.scrollHeight - scrollValues.clientHeight);
    }, []);

    const containerClassName = buildClassNames(styles, [
        'container',
        !isOpen && 'container--hidden',
    ]);

    const closeButtonClassName = mergeClassNames([
        'link link--black',
        styles['close-button'],
    ]);

    const headerClassName = buildClassNames(styles, [
        'header',
        hasTopScrollShadow && 'header--shadow',
    ]);

    const footerClassName = buildClassNames(styles, [
        'footer',
        hasBottomScrollShadow && 'footer--shadow',
    ]);

    return (
        <ClientPortal>
            <Overlay isShown={isOpen} onClick={didClosed} />
            <div className={containerClassName}>
                <div className={styles['controls']}>
                    <button
                        type="button"
                        className={closeButtonClassName}
                        onClick={didClosed}
                    >
                        Закрыть
                    </button>

                    <IconButton
                        icon={iconLogout}
                        hint="Выход"
                        testId="user-side-panel-logout-button"
                        containerClassName="h-mr-0"
                        onClick={onLogout}
                    />
                </div>

                {header && (
                    <div className={headerClassName}>
                        {header}
                    </div>
                )}

                <ScrollBar
                    onUpdate={didRecomputeScrollShadows}
                    onScroll={didRecomputeScrollShadows as any}
                    scrollContainerClassName="d-flex--1"
                    scrollPosition="right"
                    noScrollX
                    disableTrackYWidthCompensation
                >
                    <div className={styles['content']}>
                        {children}
                    </div>
                </ScrollBar>

                {footer && (
                    <div className={footerClassName}>
                        {footer}
                    </div>
                )}
            </div>
        </ClientPortal>
    );
};