import React, { useCallback } from 'react';
import {
    formatAmountText,
    formatStringWithNonBreakingSpace,
} from '@frontend/jetlend-core/src/formatters/formatUtils';
import RadioButton from '@ui/ui/inputs/RadioButton/RadioButton';
import styles from './UserAccountChoiceButton.module.scss';
import { buildClassNames } from '@ui/utils/classNameUtils';
import Tooltip from '@ui/ui/TooltipIcon/Tooltip';

export interface IProps {
    /**
     * Уникальный идентификатор счета.
     */
    id: number;
    /**
     * Флаг, что счет выбран.
     */
    active?: boolean;
    /**
     * Имя счета.
     */
    name?: string;
    /**
     * Сумма баланса счета.
     */
    amount?: number;
    /**
     * Тип отображения баланса.
     */
    amountType?: 'default'|'success'|'danger';
    /**
     * Флаг, что счет отключен и его нельзя выбрать.
     */
    disabled?: boolean;
    /**
     * @event
     * Событие срабатывает при выборе счета.
     *
     * @param id Идентификатор выбранного счета.
     */
    onClick?: (id: number) => void;
}

/**
 * Компонент кнопки выбора счета в группе одного пользователя.
 */
export default function UserAccountChoiceButton({
    id,
    active,
    name,
    amount,
    amountType = 'default',
    disabled,
    onClick,
}: IProps) {
    const hasAmount = typeof amount === 'number' && Number.isFinite(amount);

    const didClicked = useCallback(() => {
        // Если компонент заблокирован, мы не должны обрабатывать событие клика
        if (disabled) {
            return;
        }

        if (onClick) {
            onClick(id);
        }
    }, [ onClick, disabled, id ]);

    const containerClassName = buildClassNames(styles, [
        'container',
        active && 'container--active',
    ]);

    const amountClassName = buildClassNames(styles, [
        'amount',
        `amount--type-${amountType}`,
    ]);

    return (
        <RadioButton
            className={containerClassName}
            value={active}
            selectedValue
            onClick={didClicked}
            disabled={disabled}
            labelClassName="h-width--full"
            label={(
                <div className={styles['content']}>
                    <Tooltip title={name}>
                        <div className={styles['name']}>
                            {name}
                        </div>
                    </Tooltip>
                    {hasAmount && (
                        <div className={amountClassName} data-testid="amount">
                            {formatStringWithNonBreakingSpace(formatAmountText(amount))}
                        </div>
                    )}
                </div>
            )}
        />
    );
}
