'use client';

import React from 'react';
import CabinetUserSidePanel from '@frontend/jetlend-web-ui/src/features/CabinetUserSidePanel/CabinetUserSidePanel';
import CurrentUserHeader from '@frontend/jetlend-web-ui/src/components/UserSidePanel/CurrentUserHeader/CurrentUserHeader';
import { IAccountInfoApiModel } from '@app/models/common/account';

export interface IProps {
    /**
     * Объект с информацией об аккаунте
     */
    account: IAccountInfoApiModel;
}

/**
 * Боковая панель пользователя.
 * Содержит список подключенных аккаунтов и портфелей текущего пользователя.
 */
export default function ConnectedUserSidePanel({
    account,
}: IProps) {
    return (
        <CabinetUserSidePanel
            clientType={account.client_type}
            allowCurrentAccountClick
            header={
                <CurrentUserHeader name={account?.name || `ID ${account?.client_id}`} />
            }
            currentAccountId={account?.client_id}
        />
    );
}