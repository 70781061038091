import React from 'react';
import styles from './UsersSection.module.scss';

export interface IProps extends React.PropsWithChildren {
    /**
     * Заголовок секции.
     */
    title: string;
    /**
     * Плейсхолдер для добавление дополнительных данных в правой части заголовка.
     */
    action?: React.ReactNode;
}

/**
 * Отображение списка доступных пользователей со счетами внутри {@link UserSidePanel}
 */
export default function UsersSection({
    title,
    action,
    children,
}: IProps) {
    return (
        <div className={styles['container']}>
            <div className={styles['header']}>
                <div className={styles['title']}>
                    {title}
                </div>
                {action && (
                    <div className={styles['action']}>
                        {action}
                    </div>
                )}
            </div>
            <div className={styles['users']}>
                {children}
            </div>
        </div>
    );
}